import React, { useEffect, useState } from 'react';

// Função para salvar a data de aceitação dos cookies no localStorage
function setCookieAcceptance() {
    const now = new Date();
    localStorage.setItem('cookieAcceptedDate', now.toISOString());
}

// Função para verificar se já passou o prazo de 7 dias desde a aceitação
function hasCookieExpired() {
    const savedDate = localStorage.getItem('cookieAcceptedDate');
    if (savedDate) {
        const now = new Date();
        const pastDate = new Date(savedDate);
        const diffInDays = (now.getTime() - pastDate.getTime()) / (1000 * 60 * 60 * 24);
        return diffInDays >= 7;
    }
    return true;
}

export default function CookieConsent() {
    const [showBanner, setShowBanner] = useState(false);

    // Verifica se deve mostrar o banner quando o componente monta
    useEffect(() => {
        if (hasCookieExpired()) {
            setShowBanner(true);
        }
    }, []);

    const acceptCookies = () => {
        setCookieAcceptance();
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
        <div className="fixed bottom-0 w-full bg-gray-800 text-white p-4 flex gap-5 flex-col md:flex-row md:justify-between md:items-center z-50">
            <p className=''>Este site usa cookies para melhorar a experiência do usuário.</p>
            <button
                className="bg-white px-5 py-2 rounded-full text-blue-700 hover:bg-blue-700 hover:text-white transition-all"
                onClick={acceptCookies}
            >
                Aceitar
            </button>
        </div>
    );
}