import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoYoutube } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../dados/api-client';
import { IRodape } from '../dados/interfaces/home';
import { useQuery } from 'react-query';
import CookieConsent from './cookieConsent';
import WhatsApp from './whatsapp';
import DOMPurify from 'dompurify';

const fetchData = async () => {
    const response = await apiClient('/dados', {
        method: 'GET',
    });
    return response;
};

export default function CFooter() {

    const navigate = useNavigate();

    const navegar = (rota: string) => {
        navigate(rota);
    }

    const navegarExterno = (rota: string) => {
        window.open(rota, '_blank');
    }

    const { data, error, isLoading } = useQuery<IRodape>('footerData', fetchData);

    if (isLoading) return <div className='w-full h-[400px] bg-black animate-pulse'></div>;
    if (error) return <div className='w-full h-[400px] bg-black animate-pulse justify-center items-center'><p>Erro ao carregar dados</p></div>;

    const sanitizedHtml = DOMPurify.sanitize(data?.rodape || '');

    return (
        <section className="flex flex-row justify-center items-center bg-black px-5 xl:px-0">

            <div className="flex flex-col flex-1 max-w-7xl justify-between py-20 gap-10">

                <div className='flex flex-col lg:flex-row w-full justify-between gap-10'>
                    <div className='flex flex-col lg:flex-row gap-10 flex-1'>

                        <div className='flex flex-col gap-5 text-white'>
                            <p className='font-bold'>Empresa</p>
                            <p onClick={() => navegar('/sobre')} className='cursor-pointer transition-all hover:text-blue-700 self-start w-auto'>Sobre nós</p>
                            <p onClick={() => navegar('/privacidade')} className='cursor-pointer transition-all hover:text-blue-700 self-start w-auto'>Política de privacidade</p>
                            <p onClick={() => navegar('/contato')} className='cursor-pointer transition-all hover:text-blue-700 self-start w-auto'>Contato</p>
                            <p onClick={() => navegarExterno('https://app.chbrental.com.br')} className='cursor-pointer transition-all hover:text-blue-700 self-start w-auto'>Colaboradores</p>
                        </div>

                        <div className='flex flex-col gap-5 text-white'>
                            <p className='font-bold'>Mais</p>
                            <p onClick={() => navegarExterno('https://app.chbrental.com.br')} className='cursor-pointer transition-all hover:text-blue-700 self-start w-auto'>CHB Store</p>
                            <p onClick={() => navegar('/trabalhe-conosco')} className='cursor-pointer transition-all hover:text-blue-700 self-start w-auto'>Trabalhe conosco</p>
                        </div>

                    </div>
                    <div className='w-full text-white lg:w-[40%] p-10 bg-white bg-opacity-10 rounded-xl'>
                        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }}></div>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row gap-10 lg:gap-0 text-center lg:text-start w-full justify-between items-center border-t border-white border-opacity-10 pt-10">
                    <img src='/logo.svg' alt='imagem logo'></img>
                    <div className="flex flex-row gap-5">
                        <button className="transition-all text-white text-[25px] hover:opacity-70" onClick={() => navegarExterno('https://www.facebook.com/chbrentalsp/')}>
                            <IoLogoFacebook></IoLogoFacebook>
                        </button>
                        <button className="transition-all text-white text-[25px] hover:opacity-70" onClick={() => navegarExterno('https://www.instagram.com/chbrental/')}>
                            <IoLogoInstagram></IoLogoInstagram>
                        </button>
                        <button className="transition-all text-white text-[25px] hover:opacity-70" onClick={() => navegarExterno('https://www.linkedin.com/company/chbrental/')}>
                            <IoLogoLinkedin></IoLogoLinkedin>
                        </button>
                        <button className="transition-all text-white text-[25px] hover:opacity-70" onClick={() => navegarExterno('https://www.youtube.com/@CHBRental-qb3hq')}>
                            <IoLogoYoutube></IoLogoYoutube>
                        </button>
                    </div>
                    <p className='text-white'>© 2024 CHB RENTAL, Todos os direitos reservados</p>
                </div>

            </div>

            <CookieConsent></CookieConsent>

            <WhatsApp></WhatsApp>

        </section>
    )
}