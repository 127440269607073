import React from "react";

const CDenunciaPrimeiraSection = React.memo(() => {
    return (
        <section className="flex flex-col justify-center items-center gap-10 py-20 bg-gray-100 px-5 xl:px-0">
            <div className="flex flex-col gap-10 max-w-7xl relative w-full">
                <div className="flex flex-col gap-5">
                    <p className="text-[20px] text-black font-medium">DENÚNCIA</p>
                    <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-black font-bold">Entre em contato com nosso time e nos conte o que aconteceu</p>
                </div>
            </div>
        </section>
    )
});

export default CDenunciaPrimeiraSection;