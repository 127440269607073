import React from "react";
import { IoArrowForwardOutline } from 'react-icons/io5';
import { IPrimeiraSection } from "../../../dados/interfaces/contato";
import { useNavigate } from "react-router-dom";

interface IProps {
    dados: IPrimeiraSection
}

const CConoscoPrimeiraSection = React.memo((props: IProps) => {

    const navigate = useNavigate();

    const navegar = (rota: string) => {
        navigate(rota);
    }
    
    return (
        <section className="flex flex-col justify-center items-center gap-10 py-20 bg-gray-100 px-5 xl:px-0 relative">

            <div className="flex flex-col gap-10 max-w-7xl relative w-full z-30">
                <div className="flex flex-col gap-5">
                    <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-white font-bold">{props.dados.titulo}</p>
                    <p className="text-[20px] text-white font-medium">{props.dados.texto}</p>
                </div>
                <button className="flex flex-row items-center gap-2 px-5 py-3 rounded-full transition-all bg-gray-300 text-gray-700 hover:bg-red-500 hover:text-white self-end" onClick={() => navegar('/denuncia')}>
                    <p className="text-center text-sm font-medium">Fazer uma denúncia</p>
                    <IoArrowForwardOutline />
                </button>
            </div>

            <div className="w-full h-full absolute z-10">
                <img alt="imagem" className="w-full h-full object-cover" src={props.dados.imagem}></img>
            </div>

            <div className="w-full h-full bg-black absolute opacity-80 z-20"></div>

        </section>
    )
});

export default CConoscoPrimeiraSection;