import { apiConfig } from './api-config';

interface ApiOptions {
  method: string;
  body?: any;
}

export const apiClient = async (endpoint: string, options: ApiOptions) => {
  const url = `${apiConfig.baseUrl}${endpoint}`;

  const response = await fetch(url, {
    method: options.method,
    headers: apiConfig.headers,
    body: JSON.stringify(options.body),
  });

  if (!response.ok) {
    throw new Error('API request failed');
  }

  return response.json();
};
