import { IoLogoWhatsapp } from 'react-icons/io5';

export default function WhatsApp() {

    const navegarWhats = () => {
        let numero = '11-2909-1757';
        let formatado = numero.replace(/\D/g, '');
        formatado = '55' + formatado;
        window.open(`https://wa.me/${formatado}`, '_blank');
    }

    return (
        <button className="fixed right-10 bottom-10 animate-bounce hover:bg-green-500 px-5 py-3 bg-green-600 shadow-lg flex flex-row items-center gap-5 rounded-full transition-all z-40" onClick={() => navegarWhats()}>
            <p className="text-white text-center font-medium text-[16px]">Precisando de ajuda?</p>
            <IoLogoWhatsapp className='text-white text-[20px]' />
        </button>
    );
}