import React, { useState } from 'react';
import { IoArrowForwardOutline, IoRefreshOutline } from 'react-icons/io5';
import { IPrimeiraSection } from "../../../dados/interfaces/contato";
import { useForm, Controller } from 'react-hook-form';
import DOMPurify from 'dompurify';
import { apiClient } from '../../../dados/api-client';

interface IProps {
    dados: IPrimeiraSection
}

const CContatoSegundaSection = React.memo((props: IProps) => {

    const { control, handleSubmit, formState: { errors, isSubmitting }, reset } = useForm({
        defaultValues: {
            nome: '',
            email: '',
            mensagem: ''
        },
        mode: 'onBlur',
    });

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [apiError, setApiError] = useState<string | null>(null);

    const onSubmit = async (data: any) => {
        try {
            setSuccessMessage(null);
            setApiError(null);
            
            const purifiedData = {
                nome: DOMPurify.sanitize(data.nome),
                email: DOMPurify.sanitize(data.email),
                mensagem: DOMPurify.sanitize(data.mensagem),
            };

            await apiClient('/contatos/cadastrar', {
                method: 'POST',
                body: purifiedData,
            });

            setSuccessMessage('Sua mensagem foi enviada com sucesso!');
            reset();
        } catch (error) {
            setApiError('Não foi possível enviar sua mensagem, tente novamente mais tarde.');
            console.error(error);
        }
    };

    return (
        <section className="flex flex-col justify-center items-center gap-10 py-20 bg-white px-5 xl:px-0">

            <div className="flex flex-col gap-10 max-w-7xl relative w-full border border-gray-300 rounded-xl p-10">

                <div className="flex flex-col gap-5">
                    <p className="text-[30px] text-black font-bold">{props.dados.tituloForm}</p>
                    <p className="text-[20px] text-black font-normal">{props.dados.textoForm}</p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-10'>
                    <div className='flex flex-col lg:flex-row justify-between gap-10'>
                        <div className='flex flex-col w-full gap-3'>
                            <label htmlFor="nome" className='text-[18px] text-black font-medium'>Nome</label>
                            <Controller
                                name="nome"
                                control={control}
                                rules={{ required: 'Nome é um campo obrigatório' }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        id="nome"
                                        type="text"
                                        className='px-3 py-2 border border-gray-300 outline-none rounded-md'
                                    />
                                )}
                            />
                            {errors.nome && <p className='text-red-700 border-l-4 border-red-500 px-5 py-1w-auto self-start'>{errors.nome.message}</p>}
                        </div>

                        <div className='flex flex-col w-full gap-3'>
                            <label htmlFor="email" className='text-[18px] text-black font-medium'>E-mail</label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{ required: 'E-mail é um campo obrigatório', pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'E-mail inválido' } }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        id="email"
                                        type="email"
                                        className='px-3 py-2 border border-gray-300 outline-none rounded-md'
                                    />
                                )}
                            />
                            {errors.email && <p className='text-red-700 border-l-4 border-red-500 px-5 py-1w-auto self-start'>{errors.email.message}</p>}
                        </div>
                    </div>

                    <div className='flex flex-col gap-3'>
                        <label htmlFor="mensagem" className='text-[18px] text-black font-medium'>Mensagem</label>
                        <Controller
                            name="mensagem"
                            control={control}
                            rules={{ required: 'Mensagem é um campo obrigatório' }}
                            render={({ field }) => (
                                <textarea
                                    {...field}
                                    id="mensagem"
                                    className='px-3 py-2 resize-none h-[200px] border border-gray-300 outline-none rounded-md'
                                />
                            )}
                        />
                        {errors.mensagem && <p className='text-red-700 border-l-4 border-red-500 px-5 py-1w-auto self-start'>{errors.mensagem.message}</p>}
                    </div>

                    {apiError && <p className='text-red-700 border-l-4 border-red-500 px-5 py-1w-auto self-start'>{apiError}</p>}
                    {successMessage && <p className='text-green-700 border-l-4 border-green-500 px-5 py-1w-auto self-start'>{successMessage}</p>}

                    <button type="submit" className="flex flex-row items-center gap-2 px-5 py-3 rounded-full transition-all bg-blue-700 hover:bg-opacity-70 self-end" disabled={isSubmitting}>
                        {isSubmitting ? (
                            <>
                                <p className="text-white text-center text-sm font-medium">Enviando mensagem</p>
                                <IoRefreshOutline className='text-white animate-spin' />
                            </>
                        ) : (
                            <>
                                <p className="text-white text-center text-sm font-medium">Enviar mensagem</p>
                                <IoArrowForwardOutline className='text-white' />
                            </>
                        )}
                    </button>
                </form>

            </div>

        </section>
    )
});

export default CContatoSegundaSection;