import { apiClient } from '../../dados/api-client';
import Loading from '../../componentes/loading';
import CHeader from '../../componentes/c-header';
import CFooter from '../../componentes/c-footer';
import { IContato } from '../../dados/interfaces/contato';
import CConoscoPrimeiraSection from './componentes/c-primeira-section';
import CConoscoSegundaSection from './componentes/c-segunda-section';
import CConoscoTerceiraSection from './componentes/c-terceira-section';
import { useQuery } from 'react-query';
import ErroData from '../../componentes/erroData';
import { Helmet } from 'react-helmet-async';

const fetchData = async () => {
    const response = await apiClient('/conosco', {
        method: 'GET',
    });
    return response;
};

export default function TrabalheConosco() {

    const { data, error, isLoading } = useQuery<IContato>('conoscoData', fetchData);

    if (isLoading) return <Loading />;
    if (error) return <ErroData></ErroData>;

    return (
        <>
            <Helmet>
                <title>CHB Rental | Trabalhe conosco</title>
                <meta name="description" content="Venha trabalhar em uma das maiores empresas do setor siderúrgico" />
                <meta name="keywords" content="locação, equipamentos, implementos, venda, siderúrgico" />
                <meta name="og:title" content="CHB Rental | Trabalhe conosco" />
            </Helmet>
            <CHeader />
            <main className="flex min-h-screen w-full flex-col bg-white">

                <section className="flex flex-col w-full">

                    <CConoscoPrimeiraSection dados={data?.primeiraSection!}></CConoscoPrimeiraSection>
                    <CConoscoSegundaSection dados={data?.primeiraSection!}></CConoscoSegundaSection>
                    <CConoscoTerceiraSection dados={data?.segundaSection!}></CConoscoTerceiraSection>

                </section>

            </main>
            <CFooter />
        </>
    );

};