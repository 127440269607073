export default function Loading() {
    return (
        <div className="flex flex-col w-screen h-screen gap-10">
            <div className="flex flex-col">
                <div className="bg-blue-700 animate-pulse h-[40px] w-full"></div>
                <div className="bg-black animate-pulse h-[80px] w-full"></div>
                <div className="bg-gray-300 animate-pulse h-[300px] w-full"></div>
            </div>
            <div className="w-full flex flex-col justify-center items-center gap-10">
                <div className="w-full flex flex-col gap-10 max-w-7xl">
                    <div className="w-[30%] h-[20px] animate-pulse bg-gray-200 rounded-full"></div>
                    <div className="w-[70%] h-[25px] animate-pulse bg-gray-200 rounded-full"></div>
                    <div className="w-[60%] h-[25px] animate-pulse bg-gray-200 rounded-full"></div>
                    <div className="w-[65%] h-[25px] animate-pulse bg-gray-200 rounded-full"></div>
                </div>
            </div>
        </div>
    )
}