import CHeader from '../../componentes/c-header';
import CFooter from '../../componentes/c-footer';
import CDenunciaPrimeiraSection from './componentes/c-primeira-section';
import CDenunciaSegundaSection from './componentes/c-segunda-section';
import { Helmet } from 'react-helmet-async';

export default function Denuncia() {

    return (
        <>
            <Helmet>
                <title>CHB Rental | Denúncia</title>
                <meta name="description" content="Encontrou algo de errado? Entre em contato" />
                <meta name="keywords" content="locação, equipamentos, implementos, venda, siderúrgico" />
                <meta name="og:title" content="CHB Rental | Denúncia" />
            </Helmet>
            <CHeader />
            <main className="flex min-h-screen w-full flex-col bg-white">

                <section className="flex flex-col w-full">

                    <CDenunciaPrimeiraSection></CDenunciaPrimeiraSection>

                    <CDenunciaSegundaSection></CDenunciaSegundaSection>

                </section>

            </main>
            <CFooter />
        </>
    );

};