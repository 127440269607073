import { useEffect, useState } from 'react';
import { ISlide } from '../../../dados/interfaces/home';
import { IoArrowForwardOutline } from 'react-icons/io5';

interface IProps {
    dados: Array<ISlide>
}

export default function CSlide(props: IProps) {

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === props.dados.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000); // Auto change slides every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [props.dados]);

    const navegarExterno = (rota: string) => {
        window.open(rota, '_blank');
    }

    return (
        <div className="relative w-full overflow-hidden bg-blue-700">
            {/* Slides */}
            <div className="flex transition-transform duration-700 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {props.dados.map((slide, index) => (
                    <div key={index} className="min-w-full relative">
                        {/* Aspect ratio wrapper */}
                        <div className="w-full aspect-w-16 aspect-h-9">
                            <img
                                src={slide.imagem}
                                alt="Descrição"
                                className="object-contain w-full h-full"
                            />
                        </div>

                        {/* Content Overlay */}
                        <div className='absolute inset-0 flex justify-center items-center'>
                            <div className='flex flex-row w-full h-full justify-center items-center relative px-5 xl:px-0'>
                                <div className='absolute inset-0 bg-gradient-to-t from-black z-10'></div>
                                <div className='flex flex-col h-full w-full max-w-7xl justify-end items-start gap-5 py-10 z-20'>
                                    <h1 className='text-white w-full text-[14px] md:text-[15px] lg:text-[20px] xl:text-[25px] font-medium'>{slide.titulo}</h1>
                                    <button className="flex items-center gap-2 px-5 py-3 rounded-full transition-all bg-white hover:bg-opacity-70" onClick={() => navegarExterno(slide.link)}>
                                        <p className="text-blue-700 text-sm font-medium">Conferir</p>
                                        <IoArrowForwardOutline className='text-blue-700' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Bullets */}
            <div className="w-full absolute bottom-10 right-0 flex justify-center items-center px-5 xl:px-0">
                <div className='flex flex-row max-w-7xl w-full justify-end gap-3'>
                    {props.dados.map((_, index) => (
                        <button
                            key={index}
                            className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-white' : 'bg-white bg-opacity-30'}`}
                            onClick={() => setCurrentIndex(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
