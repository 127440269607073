import { apiClient } from '../../dados/api-client';
import Loading from '../../componentes/loading';
import CHeader from '../../componentes/c-header';
import CFooter from '../../componentes/c-footer';
import { IContato } from '../../dados/interfaces/contato';
import CContatoPrimeiraSection from './componentes/c-primeira-section';
import CContatoSegundaSection from './componentes/c-segunda-section';
import CContatoTerceiraSection from './componentes/c-terceira-section';
import { useQuery } from 'react-query';
import ErroData from '../../componentes/erroData';
import { Helmet } from 'react-helmet-async';

const fetchData = async () => {
    const response = await apiClient('/contatos', {
        method: 'GET',
    });
    return response;
};

export default function Contato() {

    const { data, error, isLoading } = useQuery<IContato>('contatoData', fetchData);

    if (isLoading) return <Loading />;
    if (error) return <ErroData></ErroData>;

    return (
        <>
            <Helmet>
                <title>CHB Rental | Contato</title>
                <meta name="description" content="Entre em contato com nosso time e tire todas as suas dúvidas" />
                <meta name="keywords" content="locação, equipamentos, implementos, venda, siderúrgico" />
                <meta name="og:title" content="CHB Rental | Contato" />
            </Helmet>
            <CHeader />
            <main className="flex min-h-screen w-full flex-col bg-white">

                <section className="flex flex-col w-full">

                    <CContatoPrimeiraSection dados={data?.primeiraSection!}></CContatoPrimeiraSection>
                    <CContatoSegundaSection dados={data?.primeiraSection!}></CContatoSegundaSection>
                    <CContatoTerceiraSection dados={data?.segundaSection!}></CContatoTerceiraSection>

                </section>

            </main>
            <CFooter />
        </>
    );

};