import React from "react";
import { ISegundaSection } from "../../../dados/interfaces/contato";

interface IProps {
    dados: ISegundaSection
}

const CConoscoTerceiraSection = React.memo((props: IProps) => {

    const navegarExterno = (link: string) => {
        window.open(link, '_blank');
    }

    return (
        <section className="flex flex-col justify-center items-center gap-10 py-20 bg-black px-5 xl:px-0 border-b border-white border-opacity-15">
            <div className="flex flex-col gap-20 max-w-7xl relative w-full">
                <div className="flex flex-col gap-5">
                    <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-white font-bold">{props.dados.titulo}</p>
                    <p className="text-[20px] text-white font-medium">{props.dados.texto}</p>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                    {props.dados.imagens.map((imagem, index) => (
                        <button key={index} className='flex flex-col gap-5 justify-start items-center p-10 rounded-xl border border-white border-opacity-15 transition-all hover:bg-blue-700' onClick={() => navegarExterno(imagem.link)}>
                            <div className='w-[80px] h-[80px]' key={index}>
                                <img src={imagem.imagem} alt="imagem" className='object-contain'></img>
                            </div>
                            <p className="text-[20px] text-white font-medium">{imagem.titulo}</p>
                        </button>
                    ))}
                </div>
            </div>
        </section>
    )
});

export default CConoscoTerceiraSection;