import { IHome } from '../../dados/interfaces/home';
import { apiClient } from '../../dados/api-client';
import Loading from '../../componentes/loading';
import CHeader from '../../componentes/c-header';
import CSlide from './componentes/c-slide';
import CHomeSegundaSection from './componentes/c-segunda-section';
import CHomeTerceiraSection from './componentes/c-terceira-section';
import CHomeQuartaSection from './componentes/c-quarta-section';
import CHomeContato from './componentes/c-contato';
import CFooter from '../../componentes/c-footer';
import { useQuery } from 'react-query';
import ErroData from '../../componentes/erroData';
import { Helmet } from 'react-helmet-async';

const fetchData = async () => {
    const response = await apiClient('/home', {
        method: 'GET',
    });
    return response;
};

export default function Home() {

    const { data, error, isLoading } = useQuery<IHome>('homeData', fetchData);

    if (isLoading) return <Loading />;
    if (error) return <ErroData></ErroData>;

    return (
        <>
            <Helmet>
                <title>CHB Rental</title>
                <meta name="description" content="Somos referência no setor Siderúrgico com produtos e atendimento Personalizados." />
                <meta name="keywords" content="locação, equipamentos, implementos, venda, siderúrgico" />
                <meta name="og:title" content="CHB Rental" />
            </Helmet>
            <CHeader></CHeader>
            <main className="flex min-h-screen w-full flex-col bg-blue-700">

                <CSlide dados={data?.primeiraSection.slide!}></CSlide>

                <section className="flex flex-col w-full">

                    {/* <section className='flex flex-col justify-center items-center gap-10 py-20 bg-blue-700 px-5 xl:px-0'>
                    <div className="flex flex-col gap-14 max-w-7xl justify-start items-start">
                        <h1 className='text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-white font-bold'>{dados.primeiraSection.titulo}</h1>
                        <button className="flex flex-row items-center gap-2 px-5 py-3 rounded-full transition-all bg-white hover:bg-opacity-70">
                            <p className="text-blue-700 text-center text-sm font-medium">Saiba mais</p>
                            <IoArrowForwardOutline className='text-blue-700'></IoArrowForwardOutline>
                        </button>
                    </div>
                </section> */}

                    <CHomeSegundaSection dados={data?.segundaSection!}></CHomeSegundaSection>

                    <CHomeTerceiraSection dados={data?.terceiraSection!}></CHomeTerceiraSection>

                    <CHomeQuartaSection dados={data?.quartaSection!}></CHomeQuartaSection>

                    <CHomeContato dados={data?.contato!}></CHomeContato>

                </section>

            </main>
            <CFooter></CFooter>
        </>
    )
}