import React, { useState, useEffect } from 'react';
import { ITerceiraSection } from "../../../dados/interfaces/home";
import CRating from '../../../componentes/c-rating';
import { IoArrowForwardOutline, IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

interface IProps {
    dados: ITerceiraSection
}

interface Review {
    author_name: string;
    rating: number;
    text: string;
    relative_time_description: string;
}

declare global {
    interface Window {
        google: any;
    }
}

export default function CHomeTerceiraSection(props: IProps) {

    const [reviews, setReviews] = useState<Review[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [index, setIndex] = useState(0);
    const [currentReview, setCurrentReview] = useState<Review | null>(null);

    useEffect(() => {
        const initMap = () => {
            const service = new window.google.maps.places.PlacesService(
                document.createElement('div')
            );

            const request = {
                placeId: 'ChIJbeK1sT_1zpQRCp_60OhYD8I',
                fields: ['reviews'],
            };

            service.getDetails(request, (place: any, status: any) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    if (place.reviews) {
                        const filteredReviews = filterReviews(place.reviews);
                        setReviews(filteredReviews);
                    }
                } else {
                    setError('Failed to load Google reviews');
                }
            });
        };

        if (window.google && window.google.maps) {
            initMap();
        } else {
            setError('Google Maps API not loaded');
        }
    }, []);

    useEffect(() => {
        if (reviews.length > 0) {
            setCurrentReview(reviews[0]);
        }
    }, [reviews]);

    const filterReviews = (reviews: Review[]) => {
        const minRating = 4;
        const forbiddenAuthors = ['Miqueias Barcelos bastos'];
        const forbiddenWords = ["ruim", "péssimo", "negativo", "inadequadas", "isonomia", "exclusão", "agravante", "assédio", "insalubridade", "desigual"];

        return reviews.filter(review => {
            const containsForbiddenWord = forbiddenWords.some(word => review.text.toLowerCase().includes(word));
            const isForbiddenAuthor = forbiddenAuthors.includes(review.author_name);
            return review.rating >= minRating && !containsForbiddenWord && !isForbiddenAuthor;
        });
    };

    const next = () => {
        if (index < reviews.length - 1) {
            setIndex(index + 1);
            setCurrentReview(reviews[index + 1]);
        }
    };

    const prev = () => {
        if (index > 0) {
            setIndex(index - 1);
            setCurrentReview(reviews[index - 1]);
        }
    };

    const getInitials = (author_name: string): string => {
        const nameParts = author_name.split(' ');
        const firstInitial = nameParts[0]?.charAt(0).toUpperCase();
        const secondInitial = nameParts[1]?.charAt(0).toUpperCase();

        return firstInitial + (secondInitial || '');
    };

    const navegarWhats = () => {
        let numero = '11-2909-1757';
        let formatado = numero.replace(/\D/g, '');
        formatado = '55' + formatado;
        window.open(`https://wa.me/${formatado}`, '_blank');
    }

    return (
        <>
            <section className="flex flex-col w-full justify-center items-center gap-20 py-20 bg-blue-700 px-5 xl:px-0 overflow-hidden">

                <div className="flex flex-col gap-5 max-w-7xl justify-start items-start">

                    <p className="text-[20px] text-white font-medium">{props.dados.subtitulo}</p>

                    <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-white font-bold">{props.dados.titulo}</p>

                </div>

                <div className="flex flex-col gap-14 max-w-7xl justify-start items-start">

                    <div className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
                        <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
                            {props.dados.marcas.map((item, index) => (
                                <li key={index}>
                                    <img className="object-contain h-[40px] w-auto" src={item.imagem} alt="logo marca"></img>
                                </li>
                            ))}
                        </ul>
                        <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
                            {props.dados.marcas.map((item, index) => (
                                <li key={index}>
                                    <img className="object-contain h-[40px] w-auto" src={item.imagem} alt="logo marca"></img>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>

            </section>

            <section className="flex flex-col w-full justify-center items-center gap-20 py-20 bg-blue-800 px-5 xl:px-0 overflow-hidden">

                <div className="flex flex-col gap-5 w-full max-w-7xl justify-start items-start">

                    <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-white font-bold text-start">{props.dados.titulo_depoimento}</p>

                </div>

                <div className="flex flex-col gap-14 w-full max-w-7xl justify-start items-start">

                    <div className="flex flex-col flex-1 p-10 gap-10 rounded-xl bg-blue-100 bg-opacity-30 w-full">

                        {error ? (
                            <p>{error}</p>
                        ) : currentReview ? (
                            <div className='flex flex-col gap-10'>
                                <div className='flex flex-col gap-10'>
                                    <p className="text-white text-[25px] font-medium">"{currentReview.text}"</p>
                                    <div className='flex flex-row items-center gap-5'>
                                        <div className='flex flex-row justify-center items-center rounded-full w-[40px] h-[40px] bg-white'>
                                            <p className='font-medium text-blue-700'>{getInitials(currentReview.author_name)}</p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-white'><strong>{currentReview.author_name}</strong></p>
                                            <CRating rating={currentReview.rating} />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-row w-full justify-end gap-5'>
                                    <button onClick={prev} disabled={index === 0} className={`flex flex-row justify-center items-center w-[40px] h-[40px] rounded-full bg-white ${index === 0 ? 'opacity-50' : 'cursor-pointer'}`}>
                                        <IoChevronBackOutline className='text-blue-700'></IoChevronBackOutline>
                                    </button>
                                    <button onClick={next} disabled={index === reviews.length - 1} className={`flex flex-row justify-center items-center w-[40px] h-[40px] rounded-full bg-white ${index === reviews.length - 1 ? 'opacity-50' : 'cursor-pointer'}`}>
                                        <IoChevronForwardOutline className='text-blue-700'></IoChevronForwardOutline>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <p>Carregando reviews...</p>
                        )}


                    </div>

                    <div className="flex flex-col w-full gap-3 items-start">
                        <p className="text-[13px] text-white font-semibold sm:text-[15px] md:text-[16px] lg:text-[18px] xl:text-[20px]">{props.dados.callToAction.texto}</p>
                        <button className="flex flex-row items-center gap-2 px-5 py-3 rounded-full transition-all bg-white hover:bg-opacity-70" onClick={() => navegarWhats()}>
                            <p className="text-blue-700 text-center text-sm font-medium">{props.dados.callToAction.textoBotao}</p>
                            <IoArrowForwardOutline className='text-blue-700'></IoArrowForwardOutline>
                        </button>
                    </div>

                </div>

            </section>
        </>
    )
}