import { apiClient } from '../../dados/api-client';
import Loading from '../../componentes/loading';
import CHeader from '../../componentes/c-header';
import CFooter from '../../componentes/c-footer';
import { ISobre } from '../../dados/interfaces/sobre';
import CSobrePrimeiraSection from './componentes/c-primeira-section';
import CSobreSegundaSection from './componentes/c-segunda-section';
import { useQuery } from 'react-query';
import ErroData from '../../componentes/erroData';
import { Helmet } from 'react-helmet-async';

const fetchData = async () => {
    const response = await apiClient('/sobre', {
        method: 'GET',
    });
    return response;
};

export default function Sobre() {

    const { data, error, isLoading } = useQuery<ISobre>('sobreData', fetchData);

    if (isLoading) return <Loading />;
    if (error) return <ErroData></ErroData>;

    const extractVideoId = (url: string): string => {
        const regex = /(?:youtu\.be\/|youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=))([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return match ? match[1] : '';
    };

    const videoId = extractVideoId(data?.quintaSection?.video || '');

    return (
        <>
            <Helmet>
                <title>CHB Rental | Sobre</title>
                <meta name="description" content="A CHB RENTAL tem tradição de mercado e visão de futuro" />
                <meta name="keywords" content="locação, equipamentos, implementos, venda, siderúrgico" />
                <meta name="og:title" content="CHB Rental | Sobre" />
            </Helmet>
            <CHeader></CHeader>
            <main className="flex min-h-screen w-full flex-col bg-blue-700">

                <section className="flex flex-col w-full">

                    <CSobrePrimeiraSection dados={data?.primeiraSection!}></CSobrePrimeiraSection>

                    <CSobreSegundaSection dados={data?.segundaSection!} dadost={data?.terceiraSection!} dadosq={data?.quartaSection!}></CSobreSegundaSection>

                    <section className="flex flex-col justify-center items-center gap-10 py-20 bg-blue-700 px-5 xl:px-0 relative border-b border-white border-opacity-10">

                        <div className="flex flex-col gap-20 max-w-7xl relative w-full">

                            <div className="flex flex-col gap-5">
                                <p className="text-[20px] text-white font-medium">{data?.quintaSection.subtitulo}</p>
                                <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-white font-bold">{data?.quintaSection.titulo}</p>
                            </div>

                            <div className="video-container">
                                {videoId ? (
                                    <iframe
                                        src={`https://www.youtube.com/embed/${videoId}`}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                        style={{ height: '400px', width: '100%', border: 'none' }} // Set height to 300px
                                    ></iframe>
                                ) : (
                                    <p>No video available</p>
                                )}
                            </div>

                        </div>

                    </section>

                </section>

            </main>
            <CFooter></CFooter>
        </>
    )
}