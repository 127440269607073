import { apiClient } from '../../dados/api-client';
import Loading from '../../componentes/loading';
import CHeader from '../../componentes/c-header';
import CFooter from '../../componentes/c-footer';
import { useQuery } from 'react-query';
import ErroData from '../../componentes/erroData';
import { useNavigate, useParams } from 'react-router-dom';
import { ICategoria } from '../../dados/interfaces/servicos';
import { Helmet } from 'react-helmet-async';

const fetchData = async (id: string) => {
    const response = await apiClient(`/equipamentos?categoria_id=${id}`, {
        method: 'GET',
    });
    return response;
};

export default function Categorias() {

    const navigate = useNavigate();

    const navegar = (rota: string) => {
        navigate(rota);
    };

    const { id } = useParams();

    const { data, isLoading, error } = useQuery<Array<ICategoria>>(['categoriasData', id], () => fetchData(id!), {
        enabled: !!id,
    });

    if (isLoading) return <Loading />;
    if (error) return <ErroData></ErroData>;

    return (
        <>
            <Helmet>
                <title>CHB Rental | Equipamentos</title>
                <meta name="description" content="Conheça nosso catálogo de quipamentos" />
                <meta name="keywords" content="locação, equipamentos, implementos, venda, siderúrgico" />
                <meta name="og:title" content="CHB Rental | equipamentos" />
            </Helmet>
            <CHeader></CHeader>
            <main className="flex min-h-screen w-full flex-col bg-black">

                <section className="flex flex-col w-full">

                    <section className="flex flex-col justify-center items-center gap-10 py-20 bg-black px-5 xl:px-0 relative">

                        <div className="flex flex-col gap-20 max-w-7xl relative w-full">

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">

                                {data?.map((item, index) => (

                                    <div key={index} className="relative cursor-pointer transition-all rounded-xl hover:scale-105 overflow-hidden" onClick={() => navegar(`/categorias/detalhe/${item.id}`)}>
                                        <div className="aspect-w-16 aspect-h-9 w-full relative group">
                                            <img
                                                src={item.thumb}
                                                alt="imagem equipamento"
                                                className="w-full h-full object-cover rounded-xl z-20 bg-white bg-opacity-20"
                                            />

                                            <div className="absolute opacity-0 group-hover:opacity-100 inset-0 flex flex-row p-5 w-full h-full bg-blue-700 justify-center items-center z-10">
                                                <p className="text-white font-medium text-center">{item.nome}</p>
                                            </div>
                                        </div>
                                    </div>

                                ))}

                            </div>

                        </div>

                    </section>

                </section>

            </main>
            <CFooter></CFooter>
        </>
    )
}