import { apiClient } from '../../dados/api-client';
import Loading from '../../componentes/loading';
import CHeader from '../../componentes/c-header';
import CFooter from '../../componentes/c-footer';
import { useQuery } from 'react-query';
import ErroData from '../../componentes/erroData';
import { useParams } from 'react-router-dom';
import { ICategoria } from '../../dados/interfaces/servicos';
import DOMPurify from 'dompurify';

const fetchData = async (id: string) => {
    const response = await apiClient(`/equipamentos/detalhe/${id}`, {
        method: 'GET',
    });
    return response;
};

export default function ServicosDetalhes() {

    const { id } = useParams();

    const { data, isLoading, error } = useQuery<ICategoria>(['servicosDetalhesData', id], () => fetchData(id!), {
        enabled: !!id,
    });

    if (isLoading) return <Loading />;
    if (error) return <ErroData></ErroData>;

    const sanitizedHtml = DOMPurify.sanitize(data?.descricao || '');

    return (
        <>
            <CHeader></CHeader>
            <main className="flex min-h-screen w-full flex-col bg-white">

                <section className="flex flex-col w-full">

                    <section className="flex flex-col justify-center items-center gap-10 py-20 bg-white px-5 xl:px-0 relative">

                        <div className="flex flex-col gap-14 max-w-7xl relative">

                            <div className="flex flex-col gap-5 z-30 font-bold">
                                <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-white font-fold">{data?.nome}</p>
                            </div>

                        </div>

                        <div className="w-full h-full absolute z-10">
                            <img alt="imagem" className="w-full h-full object-cover" src={data?.imagem}></img>
                        </div>

                        <div className="w-full h-full bg-black absolute opacity-80 z-20"></div>

                    </section>

                    <section className="flex flex-col justify-center items-center gap-10 py-20 bg-white px-5 xl:px-0 relative">

                        {data?.descricao &&
                            <div className="flex flex-col gap-10 max-w-7xl relative w-full">

                                <p className='text-black text-[30px] font-bold'>Descrição</p>
                                <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }}></div>

                            </div>
                        }

                    </section>

                </section>

            </main>
            <CFooter></CFooter>
        </>
    )
}