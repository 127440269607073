export default function NotFound() {
    return (
        <section className="w-screen h-screen bg-black flex flex-col justify-center items-center gap-10 text-white">
            <img src='/logo.svg' alt="logo" />
            <div className="flex flex-col gap-5 justify-start">
                <p className="text-[25px] font-bold">404</p>
                <p className="text-[20px] font-medium">Parece que a página que você tentou acessar não existe</p>
            </div>
        </section>
    )
}