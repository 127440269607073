import React from "react";
import { IQuartaSection, ISegundaSection, ITerceiraSection } from "../../../dados/interfaces/sobre";

interface IProps {
    dados: ISegundaSection,
    dadost: ITerceiraSection,
    dadosq: IQuartaSection
}

const CSobreSegundaSection = React.memo((props: IProps) => {
    return (
        <section className="flex flex-col justify-center items-center gap-10 py-20 bg-white px-5 xl:px-0 relative">

            <div className="flex flex-col gap-20 max-w-7xl relative w-full">

                <div className="flex flex-col lg:flex-row items-center gap-10">
                    <div className="flex flex-row justify-center items-center h-[100px] w-[100px]">
                        <img src={props.dados.imagem} alt="imagem" className="object-contain w-full h-full"></img>
                    </div>
                    <div className="flex flex-col gap-5">
                        <p className="text-[20px] text-black font-medium">{props.dados.subtitulo}</p>
                        <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-black font-bold">{props.dados.titulo}</p>
                        <p className="text-[20px] text-black font-normal">{props.dados.texto}</p>
                    </div>
                </div>
                
                <div className="flex flex-col lg:flex-row items-center justify-end w-full gap-10">
                    <div className="flex flex-row justify-center items-center h-[100px] w-[100px]">
                        <img src={props.dadost.imagem} alt="imagem" className="object-contain w-full h-full"></img>
                    </div>
                    <div className="flex flex-col gap-5">
                        <p className="text-[20px] text-black font-medium">{props.dadost.subtitulo}</p>
                        <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-black font-bold">{props.dadost.titulo}</p>
                        <p className="text-[20px] text-black font-normal">{props.dadost.texto}</p>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row items-center gap-10">
                    <div className="flex flex-row justify-center items-center h-[100px] w-[100px]">
                        <img src={props.dadosq.imagem} alt="imagem" className="object-contain w-full h-full"></img>
                    </div>
                    <div className="flex flex-col gap-5">
                        <p className="text-[20px] text-black font-medium">{props.dadosq.subtitulo}</p>
                        <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-black font-bold">{props.dadosq.titulo}</p>
                        <p className="text-[20px] text-black font-normal">{props.dadosq.texto}</p>
                    </div>
                </div>

            </div>

        </section>
    )
})

export default CSobreSegundaSection;