import React from 'react';
import { IoStarOutline, IoStar, IoStarHalf } from 'react-icons/io5';

interface IProps {
    rating: number
}

const CRating = (props: IProps) => {

  const stars = Math.min(Math.max(props.rating, 0), 5);
  
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      {[...Array(5)].map((_, index) => {
        if (index < Math.floor(stars)) {
          return (
            <span key={index}>
              <IoStar style={{ color: 'gold', fontSize: '24px' }} />
            </span>
          );
        } else if (index < stars) {
          return (
            <span key={index}>
              <IoStarHalf style={{ color: 'gold', fontSize: '24px' }} />
            </span>
          );
        } else {
          return (
            <span key={index}>
              <IoStarOutline style={{ color: 'gold', fontSize: '24px' }} />
            </span>
          );
        }
      })}
    </div>
  );
};

export default CRating;