import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Home from './rotas/home/home';
import Sobre from './rotas/sobre/sobre';
import Contato from './rotas/contato/contato';
import Denuncia from './rotas/denuncia/denuncia';
import TrabalheConosco from './rotas/trabalhe-conosco/trabalhe-conosco';
import Categorias from './rotas/categorias/categorias';
import ServicosDetalhes from './rotas/categorias/detalhes';
import NotFound from './rotas/notFound';
import { HelmetProvider } from 'react-helmet-async';
import Privacidade from './rotas/privacidade';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sobre" element={<Sobre />} />
            <Route path="/contato" element={<Contato />} />
            <Route path="/trabalhe-conosco" element={<TrabalheConosco />} />
            <Route path="/denuncia" element={<Denuncia />} />
            <Route path="/servicos/detalhe/:id" element={<ServicosDetalhes />} />
            <Route path="/categorias/:id" element={<Categorias />} />
            <Route path="/categorias/detalhe/:id" element={<ServicosDetalhes />} />
            <Route path="/privacidade" element={<Privacidade />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;
