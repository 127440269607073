import React from "react";
import { IPrimeiraSection } from "../../../dados/interfaces/sobre";

interface IProps {
    dados: IPrimeiraSection
}

const CSobrePrimeiraSection = React.memo((props: IProps) => {
    return (
        <section className="flex flex-col justify-center items-center gap-10 py-20 bg-white px-5 xl:px-0 relative">

            <div className="flex flex-col gap-14 max-w-7xl relative">

                <div className="flex flex-col gap-5 z-30">
                    <p className="text-[20px] text-white font-medium">{props.dados.subtitulo}</p>
                    <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-white font-bold">{props.dados.titulo}</p>
                </div>

            </div>

            <div className="w-full h-full absolute z-10">
                <img alt="imagem" className="w-full h-full object-cover" src={props.dados.imagem}></img>
            </div>

            <div className="w-full h-full bg-black absolute opacity-80 z-20"></div>

        </section>
    )
});

export default CSobrePrimeiraSection;