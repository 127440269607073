import { useEffect, useState } from "react"
import { IQuartaSection, ITab } from "../../../dados/interfaces/home"
import { useNavigate } from "react-router-dom"
import { IoArrowForwardOutline } from 'react-icons/io5'

interface IProps {
    dados: IQuartaSection
}

export default function CHomeQuartaSection(props: IProps) {

    const [tab, setTab] = useState({} as ITab)

    useEffect(() => {
        setTab(props.dados.tabs[0])
    }, [props.dados.tabs])

    const seleciona = (id: number) => {
        props.dados.tabs.forEach(element => {
            if (element.id === id) {
                setTab(element)
            }
        });
    }

    const navigate = useNavigate();

    const navegar = (rota: string) => {
        navigate(rota);
    };

    return (
        <section className="flex flex-col justify-center items-center gap-10 py-20 bg-white px-5 xl:px-0">

            <div className="flex flex-col gap-10 max-w-7xl">

                <div className="flex flex-col w-full gap-5">

                    <p className="text-[20px] text-black font-medium">{props.dados.subtitulo}</p>

                    <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-black font-bold">{props.dados.titulo}</p>

                </div>

                <div className="grid grid-cols-2 lg:grid-cols-4 gap-10">

                    {props.dados.tabs.slice(0, 4).map((item, index) => (

                        <div key={index} className={`flex flex-row justify-center items-center rounded-full py-3 px-6 cursor-pointer transition-all hover:opacity-75
                    ${item.id === tab.id ? 'bg-blue-700' : 'bg-[#E5E5E5]'}`} onClick={() => seleciona(item.id)}>

                            <p className={`font-semibold text-blue-700 truncate
                        ${item.id === tab.id ? 'text-white' : 'text-[#686868]'}`}>{item.nome}</p>

                        </div>

                    ))}

                </div>

                {tab.equipamentos ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                        {tab.equipamentos.slice(0, 6).map((item, index) => (
                            <div
                                key={index}
                                className="relative cursor-pointer transition-all rounded-xl hover:scale-105 overflow-hidden"
                                onClick={() => navegar(`/categorias/detalhe/${item.id}`)}
                            >
                                <div className="aspect-w-16 aspect-h-9 w-full relative group">
                                    <img
                                        src={item.thumb}
                                        alt="imagem equipamento"
                                        className="w-full h-full object-cover rounded-xl z-20 bg-black bg-opacity-20"
                                    />

                                    <div className="absolute opacity-0 group-hover:opacity-100 inset-0 flex flex-row p-5 w-full h-full bg-blue-700 justify-center items-center z-10">
                                        <p className="text-white font-medium text-center">{item.nome}</p>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                ) : null}

                <div className="flex flex-row justify-start">
                    <button className="flex flex-row items-center gap-2 px-5 py-3 rounded-full transition-all bg-blue-700 hover:bg-opacity-70" onClick={() => navegar(`/categorias/${tab.equipamentos[0].categoria_id}`)}>
                        <p className="text-white text-center text-sm font-medium">Ver mais</p>
                        <IoArrowForwardOutline className='text-white'></IoArrowForwardOutline>
                    </button>
                </div>


            </div>

        </section>
    )
}