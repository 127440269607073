const API_URL = 'https://app.chbrental.com.br/api/site';

const defaultHeaders: HeadersInit = {
  'Content-Type': 'application/json',
  'CHB-API-KEY': 'rPa#N4k557pW',
};

export const apiConfig = {
  baseUrl: API_URL,
  headers: defaultHeaders,
};