import { IContatoContato } from "../../../dados/interfaces/home";
import { IoArrowForwardOutline } from 'react-icons/io5';

interface IProps {
    dados: IContatoContato
}

export default function CHomeContato(props: IProps) {

    const navegarWhats = () => {
        let numero = '11-2909-1757';
        let formatado = numero.replace(/\D/g, '');
        formatado = '55' + formatado;
        window.open(`https://wa.me/${formatado}`, '_blank');
    }

    return (
        <section className="flex flex-col h-auto lg:py-20 lg:px-5 xl:px-0 relative bg-white">

            <div className="flex flex-col w-full lg:max-w-7xl m-auto gap-10 z-40 bg-black px-5 py-20 lg:p-20 lg:rounded-xl border-b border-white border-opacity-10 lg:border-none">

                <div className="flex flex-col w-full gap-5">

                    <p className="text-[20px] text-white font-medium">{props.dados.subtitulo}</p>

                    <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-white font-bold">{props.dados.titulo}</p>

                </div>

                <div className="flex flex-col gap-3 items-start">

                    <p className="text-white text-[20px]">{props.dados.texto}</p>
                    <button className="flex flex-row items-center gap-2 px-5 py-3 rounded-full transition-all bg-white hover:bg-opacity-70" onClick={() => navegarWhats()}>
                        <p className="text-blue-700 text-center text-sm font-medium">{props.dados.callToAction.textoBotao}</p>
                        <IoArrowForwardOutline className='text-blue-700'></IoArrowForwardOutline>
                    </button>

                </div>

            </div>

            <div className="hidden lg:flex w-full h-full right-0 bottom-0 absolute bg-gradient-to-t from-black z-30 opacity-90"></div>


            <div className="hidden lg:flex w-full h-full right-0 top-0 absolute z-10 opacity-40 bg-cover bg-center" style={{ backgroundImage: `url(${props.dados.imagem_contato})` }}>
            </div>

        </section>
    )
}