import { ISegundaSectionItem } from "../../../dados/interfaces/home";
import { IoArrowForwardOutline } from 'react-icons/io5';

interface IProps {
    dados: ISegundaSectionItem
}

export default function CHomeSegundaSection(props: IProps) {

    const navegarWhats = () => {
        let numero = '11-2909-1757';
        let formatado = numero.replace(/\D/g, '');
        formatado = '55' + formatado;
        window.open(`https://wa.me/${formatado}`, '_blank');
    }

    return (
        <section className="flex flex-col justify-center items-center gap-10 py-20 bg-white px-5 xl:px-0">

            <div className="flex flex-col gap-14 max-w-7xl justify-start items-start">

                <div className="flex flex-col gap-5">

                    <p className="text-[20px] text-black font-medium">{props.dados.subtitulo}</p>

                    <p className="text-[35px] md:text-[40px] lg:text-[45px] xl:text-[50px] text-black font-bold">{props.dados.titulo}</p>

                </div>

                <div className="grid grid-cols-2 gap-5 lg:grid-cols-4 lg:gap-10 w-full">

                    {props.dados.itens.map((item, index) => (

                        <div key={index} className="flex flex-col justify-center items-center gap-5 h-[180px] rounded-[50px] 
                        cursor-pointer transition-all hover:opacity-75 hover:scale-105 bg-[#E5E5E5] break-words break-all p-5 text-center">

                            <img src={item.imagem} alt="icone do item" className="object-contain w-[45px] h-[45px]"></img>

                            <p className="w-full text-[13px] break-words break-all font-semibold sm:text-[15px] md:text-[16px] lg:text-[18px] xl:text-[20px] text-[#686868]">{item.titulo}</p>

                        </div>

                    ))}

                </div>

                <div className="flex flex-col gap-3 items-start">

                    <p className="text-[13px] text-black font-semibold sm:text-[15px] md:text-[16px] lg:text-[18px] xl:text-[20px]">{props.dados.callToAction.texto}</p>

                    <button className="flex flex-row items-center gap-2 px-5 py-3 rounded-full transition-all bg-blue-700 hover:bg-opacity-70" onClick={() => navegarWhats()}>
                        <p className="text-white text-center text-sm font-medium">{props.dados.callToAction.textoBotao}</p>
                        <IoArrowForwardOutline className='text-white'></IoArrowForwardOutline>
                    </button>

                </div>

            </div>

        </section>
    )
}